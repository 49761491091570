import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      backgroundColor: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      minHeight: 300,
      [theme.breakpoints.down('sm')]: {
        minHeight: 200,
      },
    },
    personIcon: {
      fontSize: 160,
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: 80,
      },
    },
  })
);

const NoVideoBg = () => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <PersonOutlineIcon className={styles.personIcon} />
    </Box>
  );
};

export default NoVideoBg;
