import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      minHeight: 300,
      [theme.breakpoints.down('sm')]: {
        minHeight: 200,
      },
    },
  })
);

const NoParticipantInfo = () => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Box fontSize="12" color="#fff">
        No one else is here.
      </Box>
    </Box>
  );
};

export default NoParticipantInfo;
