import React from 'react';
import { Toolbar, Box, Container, IconButton } from '@material-ui/core';
import { apiPost } from '../../api/request';
import FullpageSpinner from '../../components/FullpageSpinner';
import SentimentVeryDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';

const satisfactionLevel = ['Very Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'];

interface VideoEndingProps {
  clientName?: string;
  token?: any;
}

const VideoEnding: React.FC<VideoEndingProps> = ({ clientName, token }) => {
  // const [message, setMessage] = React.useState("");
  const [isSending, setIsSending] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const submitReview = async (rating: number) => {
    try {
      setIsSending(true);
      setError(false);
      await apiPost(`appointments/${token}/review/`, {
        data: {
          rating,
        },
      });
      setIsSending(false);
      setSuccess(true);
    } catch (error) {
      setIsSending(false);
      setError(true);
    }
  };

  const getSmileyFaces = (rating: number) => {
    const color = '#009654';
    const fontSize = 50;
    if (rating === 1) return <SentimentVeryDissatisfiedOutlinedIcon style={{ color, fontSize }} />;
    else if (rating === 2) return <SentimentDissatisfiedOutlinedIcon style={{ color, fontSize }} />;
    else if (rating === 3)
      return (
        <svg width={fontSize} height={fontSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
            fill={color}
          />
          <path
            d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
            fill={color}
          />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11.99 17.02C12.72 17.02 15 16.98 15 17V15C14 15 13.11 15 12 15C10.89 15 9 15 9 15C8.99 15.12 9 17 9 17C9.5 17 11.26 17.02 11.99 17.02Z"
            fill={color}
          />
        </svg>
      );
    else if (rating === 4) return <SentimentSatisfiedOutlinedIcon style={{ color, fontSize }} />;
    else if (rating === 5) return <SentimentVerySatisfiedOutlinedIcon style={{ color, fontSize }} />;
  };

  return (
    <Container>
      <Toolbar />
      {isSending && <FullpageSpinner />}
      {success ? (
        <>
          <Box fontSize={24} textAlign="center" color="#009654" component="h2" mt={12} mb={1}>
            Success!
          </Box>
          <Box fontSize={20} textAlign="center" component="p" m={0}>
            Thank you for the feedback.
          </Box>
        </>
      ) : (
        <>
          <Box fontSize={24} textAlign="center" component="h2" mt={12} mb={1}>
            Thank you {clientName || null}, the call has ended.
          </Box>
          {clientName && (
            <>
              <Box fontSize={20} textAlign="center" component="p" m={0} mb={1}>
                How satisfied are you with the video call?
              </Box>
              <Box component="div" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                {/* <Box width="80%" maxWidth="480px">
                  <TextField
                    fullWidth
                    id="outlined-helperText"
                    autoFocus
                    value={message}
                    placeholder="Message..."
                    variant="outlined"
                    color="primary"
                    multiline
                    rows={8}
                    rowsMax={12}
                    inputProps={{ maxLength: 800 }}
                    helperText={`${message.length}/800`}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setError(false);
                    }}
                  />
                </Box> */}
                {/* doctors rating */}
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  {satisfactionLevel.map((item, index) => (
                    <IconButton onClick={() => submitReview(index + 1)} title={item}>
                      {getSmileyFaces(index + 1)}
                    </IconButton>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      {error && (
        <Box color="#db2323" fontSize={20} textAlign="center" component="div" my={1}>
          Oops! something went wrong.
        </Box>
      )}
      {/* <Box component="div" display="flex" justifyContent="center">
        <Box width="80%" maxWidth="480px" textAlign="right">
          {message.length > 0 && !success && (
            <Button
              disabled={isSending}
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
            >
              Send
            </Button>
          )}
        </Box>
      </Box> */}
    </Container>
  );
};

export default VideoEnding;
